import Link from 'next/link'
import React from 'react'
import {CollapsibleTableLinkItemStoryblok} from 'types/storyblok-types'
import linkTypeChecker from 'util/linkTypeChecker'

type CollapsibleTableLinkItemContainerProps = {
  blok: CollapsibleTableLinkItemStoryblok
}

type CollapsibleTableLinkItemProps = {
  title: string
  url: string
  url_target: string
}

export function CollapsibleTableItemLink({
  title,
  url,
  url_target,
}: CollapsibleTableLinkItemProps) {
  return (
    <div>
      <Link
        href={url}
        target={url_target}
        className="text-body font-semibold text-dats-s5 underline"
      >
        {title}
      </Link>
    </div>
  )
}

const CollapsibleTableItemLinkContainer = ({
  blok,
}: CollapsibleTableLinkItemContainerProps) => {
  return (
    <CollapsibleTableItemLink
      title={blok.title}
      url={linkTypeChecker(blok.url)}
      url_target={blok.url.target}
    />
  )
}

export default CollapsibleTableItemLinkContainer
